const getSvgLetter = (colorArr, letter) => {
  const PREFIX = "%23";
  const DATAURL = "data:image/svg+xml;utf8,";
  const SVGSTORE = {
    A: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70.02"><title>A_CH</title><polygon points="7.38 65.18 35.72 60.26 62.13 70 62.13 0 31.06 0 7.38 65.18" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="0 70.02 31.06 60.91 31.06 0 0 0 0 70.02" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="31.06 0 8.66 50.49 53.46 50.49 31.06 0" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="53.46 50.49 8.66 50.49 0 70.02 62.13 70.02 53.46 50.49" style="fill:${PREFIX}${
      colorArr[3]
    }"/><circle cx="31.06" cy="37.02" r="10.67" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    B: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.61 70.02"><title>B_CH</title><path d="M39.83,35H19.43L15.15,12.52,19.43,0h20.4A17.49,17.49,0,0,1,57.32,17.48h0A17.49,17.49,0,0,1,39.83,35Z" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="19.43 65.18 5.47 60.91 0.01 34.88 0.01 0 19.43 0 19.43 65.18" style="fill:${PREFIX}${
      colorArr[1]
    }"/><circle cx="39.83" cy="17.55" r="9.71" transform="translate(7.98 47.22) rotate(-66.75)" style="fill:${PREFIX}${
      colorArr[2]
    }"/><path d="M40,70H0V34.88H40A17.57,17.57,0,0,1,57.61,52.45h0A17.57,17.57,0,0,1,40,70Z" style="fill:${PREFIX}${
      colorArr[3]
    }"/><circle cx="39.83" cy="52.52" r="9.71" transform="translate(-10.99 10.54) rotate(-13.27)" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    C: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.89 69.99"><title>C_CH</title><path d="M41.49,31.33l6.4,16.33V70H35A35,35,0,0,1,0,35" style="fill:${PREFIX}${
      colorArr[0]
    }"/><path d="M41,35l6.92-12.67V0H35A35,35,0,0,0,0,35H47.89" style="fill:${PREFIX}${
      colorArr[1]
    }"/><path d="M47.89,47.66H35.55A12.68,12.68,0,0,1,22.87,35h0A12.68,12.68,0,0,1,35.55,22.32H47.89Z" style="fill:${PREFIX}${
      colorArr[2]
    }"/></svg>`,
    D: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.42 70"><title>D_CH</title><path d="M19.42,0a35,35,0,0,1,35,35h0a35,35,0,0,1-35,35" style="fill:${PREFIX}${
      colorArr[0]
    }"/><path d="M19.42,0a35,35,0,0,1,35,35h0a35,35,0,0,1-35,35" style="fill:${PREFIX}${
      colorArr[1]
    }"/><rect width="19.42" height="70" style="fill:${PREFIX}${
      colorArr[2]
    }"/><circle cx="19.42" cy="35.24" r="11.41" style="fill:${PREFIX}${
      colorArr[3]
    }"/></svg>`,
    E: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.59 70.02"><title>E_CH</title><polygon points="19.42 70.02 2.52 65.93 3.94 7.49 19.42 0 54.59 17.59 54.59 52.43 19.42 70.02" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="5.36 17.59 54.59 17.59 54.59 0 19.42 0 7.16 4.14 5.36 17.59" style="fill:${PREFIX}${
      colorArr[1]
    }"/><rect x="19.42" y="52.43" width="35.17" height="17.58" style="fill:${PREFIX}${
      colorArr[2]
    }"/><rect width="19.42" height="70.01" style="fill:${PREFIX}${
      colorArr[3]
    }"/><path d="M37,47.33h0A12.23,12.23,0,0,1,24.78,35.1v0A12.23,12.23,0,0,1,37,22.85h0A12.23,12.23,0,0,1,49.24,35.08v0A12.24,12.24,0,0,1,37,47.33Z" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    F: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.15 70"><title>F_CH</title><polygon points="43.16 26.21 16.12 35 16.12 11.37 43.16 17.58 43.16 26.21" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="19.43 26.21 4.35 35.91 0 26.21 0 17.58 19.43 12.13 19.43 26.21" style="fill:${PREFIX}${
      colorArr[1]
    }"/><rect width="43.15" height="17.58" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="6.55 43.79 43.16 43.79 43.16 26.21 0 26.21 6.55 43.79" style="fill:${PREFIX}${
      colorArr[3]
    }"/><rect y="26.21" width="19.42" height="43.79" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    G: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.71 70"><title>G_CH</title><polygon points="29.21 22.33 57.71 22.33 57.71 47.66 35.38 47.66 29.21 22.33" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="57.71 70 35.38 70 29.21 47.66 57.71 47.66 57.71 70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><path d="M35.38,70H35A35,35,0,0,1,0,35H0A35,35,0,0,1,35,0h.38Z" style="fill:${PREFIX}${
      colorArr[2]
    }"/><path d="M35.38,47.67h.17A12.68,12.68,0,0,1,22.87,35h0A12.68,12.68,0,0,1,35.55,22.33h-.17Z" style="fill:${PREFIX}${
      colorArr[3]
    }"/></svg>`,
    H: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.11 70"><title>H_CH</title><polygon points="8.06 61.94 57.34 64.78 54.5 6.45 42.7 0 19.42 0 9.7 4 8.06 61.94" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="19.42 70 42.7 70 53.57 35 12.1 35 19.42 70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><rect x="7.44" y="26.21" width="47.23" height="17.58" style="fill:${PREFIX}${
      colorArr[2]
    }"/><rect x="42.7" width="19.42" height="70" style="fill:${PREFIX}${
      colorArr[3]
    }"/><rect width="19.42" height="70" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    I: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.42 70"><title>I_CH</title><polygon points="19.42 35.02 9.71 41.94 0 35.02 0 0 19.42 0 19.42 35.02" style="fill:${PREFIX}${
      colorArr[0]
    }"/><rect y="35" width="19.42" height="35" style="fill:${PREFIX}${
      colorArr[1]
    }"/></svg>`,
    J: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.2 70.02"><title>J_CH</title><polygon points="35.2 35.02 25.5 41.94 15.82 35.02 15.82 0 35.2 0 35.2 35.02" style="fill:${PREFIX}${
      colorArr[0]
    }"/><path d="M4,38.2.09,50.75.2,70a35,35,0,0,0,35-35H15.82Z" style="fill:${PREFIX}${
      colorArr[1]
    }"/><path d="M15.82,35A15.73,15.73,0,0,1,.09,50.75h0L0,35Z" style="fill:${PREFIX}${
      colorArr[2]
    }"/></svg>`,
    K: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70.02"><title>K_CH</title><polygon points="62.13 70.02 47.15 50.75 38.77 35.01 46.96 12.64 62.13 0 62.13 70.02" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="19.35 35.01 5.01 35.01 19.42 0 42.7 0 33.27 20.97 19.35 35.01" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="37.15 35.01 5.01 35.01 19.42 70.02 42.7 70.02 37.15 35.01" style="fill:${PREFIX}${
      colorArr[2]
    }"/><rect width="19.42" height="70.02" style="fill:${PREFIX}${
      colorArr[3]
    }"/><polygon points="62.12 0 42.7 0 19.35 35.01 31.8 39.31 38.77 35.01 62.12 0" style="fill:${PREFIX}${
      colorArr[4]
    }"/><polygon points="62.12 70.02 42.7 70.02 19.35 35.01 38.77 35.01 62.12 70.02" style="fill:${PREFIX}${
      colorArr[5]
    }"/></svg>`,
    L: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.83 70"><title>L_CH</title><polygon points="19.43 70 35.83 70 35.83 52.42 7.18 52.42 19.43 70" style="fill:${PREFIX}${
      colorArr[0]
    }"/><rect width="19.42" height="70" style="fill:${PREFIX}${
      colorArr[1]
    }"/></svg>`,
    M: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.9 70"><title>M_CH</title><polygon points="21.7 41.5 39.96 46.62 48.26 14.45 39.96 0 0 0 21.7 41.5" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="39.96 46.62 61.43 40.6 79.9 0 39.96 0 39.96 46.62" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="39.96 70 56.51 63.27 63.08 70 79.9 70 79.9 0 39.96 46.62 35.7 57.48 39.96 70" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="63.08 70 63.08 44.97 39.96 70 63.08 70" style="fill:${PREFIX}${
      colorArr[3]
    }"/><polygon points="0 70 16.82 70 24.25 63.41 39.96 70 39.96 46.62 0 0 0 70" style="fill:${PREFIX}${
      colorArr[4]
    }"/><polygon points="16.82 70 16.82 44.97 39.96 70 16.82 70" style="fill:${PREFIX}${
      colorArr[5]
    }"/></svg>`,
    N: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.93 70.01"><title>N_CH</title><polygon points="33.02 41.5 9.65 12.09 19.29 0.01 44.5 0.01 46.54 52.5 33.02 41.5" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="63.93 70 44.5 60.23 44.5 0.01 63.93 0.01 63.93 70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="8.46 63.01 6.96 7.42 19.29 0.01 63.93 70 44.32 70 28.09 62.24 8.46 63.01" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="19.29 70 19.29 31.25 44.32 70 19.29 70" style="fill:${PREFIX}${
      colorArr[3]
    }"/><rect width="19.28" height="70" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    O: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70"><title>O_CH</title><path d="M70,35A35,35,0,0,1,0,35" style="fill:${PREFIX}${
      colorArr[0]
    }"/><path d="M0,35a35,35,0,0,1,70,0" style="fill:${PREFIX}${
      colorArr[1]
    }"/><circle cx="35" cy="35" r="17.29" style="fill:${PREFIX}${
      colorArr[2]
    }"/><circle cx="35" cy="35" r="8.41" style="fill:${PREFIX}${
      colorArr[3]
    }"/></svg>`,
    P: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.51 70.02"><title>P_CH</title><path d="M19.42,0A28.09,28.09,0,0,1,47.51,28.09h0A28.09,28.09,0,0,1,19.42,56.17L12,31.23Z" style="fill:${PREFIX}${
      colorArr[0]
    }"/><rect width="19.42" height="70.02" style="fill:${PREFIX}${
      colorArr[1]
    }"/><path d="M9,28.09A10.3,10.3,0,1,1,19.3,38.38,10.29,10.29,0,0,1,9,28.09Z" style="fill:${PREFIX}${
      colorArr[2]
    }"/></svg>`,
    Q: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70"><title>Q_CH</title><path d="M35,70A35,35,0,0,1,35,0" style="fill:${PREFIX}${
      colorArr[0]
    }"/><path d="M35,0a35,35,0,0,1,0,70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><circle cx="35" cy="35" r="17.29" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="35 70 70 70 35 35 35 70" style="fill:${PREFIX}${
      colorArr[3]
    }"/></svg>`,
    R: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70.02"><title>R_CH</title><polygon points="62.13 70.02 30.46 42.59 34.37 0 62.13 0 62.13 70.02" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="62.13 70.02 42.82 70.02 15.59 49.23 5.25 7.28 62.13 70.02" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="43.75 70.02 19.53 70.02 12.91 56.72 19.53 42.59 43.75 70.02" style="fill:${PREFIX}${
      colorArr[2]
    }"/><path d="M40.83,42.6H19.53L11.45,23.31,19.53,0h21.3a21.3,21.3,0,0,1,21.3,21.3h0A21.3,21.3,0,0,1,40.83,42.6Z" style="fill:${PREFIX}${
      colorArr[3]
    }"/><circle cx="40.83" cy="21.3" r="11.82" transform="translate(-5.04 17.25) rotate(-22.5)" style="fill:${PREFIX}${
      colorArr[4]
    }"/><rect width="19.53" height="70.02" style="fill:${PREFIX}${
      colorArr[5]
    }"/></svg>`,
    S: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.14 70"><title>S_CH</title><polygon points="31.53 46.61 0.01 44.65 0.01 0 31.07 0 31.53 46.61" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="62.14 70 36.09 70 31.07 9.47 62.14 25.32 62.14 70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><path d="M22.33,44.64H35V22.35l27.18-3V0H22.33A22.32,22.32,0,0,0,0,22.32H0A22.32,22.32,0,0,0,22.33,44.64Z" style="fill:${PREFIX}${
      colorArr[2]
    }"/><path d="M62.14,25.32H22a3,3,0,0,1-3-3h0a3,3,0,0,1,3-3H62.14Z" style="fill:${PREFIX}${
      colorArr[3]
    }"/><path d="M39.81,25.36H31.06V47.65L0,50.65V70H39.81A22.32,22.32,0,0,0,62.13,47.68h0A22.32,22.32,0,0,0,39.81,25.36Z" style="fill:${PREFIX}${
      colorArr[4]
    }"/><path d="M0,44.65H40.16a3,3,0,0,1,3,3h0a3,3,0,0,1-3,3H0Z" style="fill:${PREFIX}${
      colorArr[5]
    }"/></svg>`,
    T: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70"><title>T_CH</title><polygon points="62.13 70 40.77 70 16.32 59.11 5.5 5.86 62.13 17.58 62.13 70" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="21.34 70 0 70 0 17.58 27 6.76 21.34 70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><rect width="62.13" height="17.58" style="fill:${PREFIX}${
      colorArr[2]
    }"/><rect x="21.34" y="17.58" width="19.42" height="52.42" style="fill:${PREFIX}${
      colorArr[3]
    }"/></svg>`,
    U: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70"><title>U_CH</title><path d="M31.06,7.43l3.71,50.66L31.06,70A31.05,31.05,0,0,1,0,38.94V0H15.86Z" style="fill:${PREFIX}${
      colorArr[0]
    }"/><path d="M62.13,0V38.94A31.06,31.06,0,0,1,31.06,70V8.79L46.26,0Z" style="fill:${PREFIX}${
      colorArr[1]
    }"/><path d="M31.06,0V54a15.19,15.19,0,0,1-15.2-15.2V0Z" style="fill:${PREFIX}${
      colorArr[2]
    }"/><path d="M46.26,0V38.75A15.19,15.19,0,0,1,31.06,54V0Z" style="fill:${PREFIX}${
      colorArr[3]
    }"/></svg>`,
    V: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70"><title>V_CH</title><polygon points="0 0 31.06 8.95 62.13 0 30.98 70 0 70 0 0" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="30.98 6.83 62.13 0 62.13 70 30.98 70 30.98 6.83" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="9.32 4.98 33.71 9.43 44.02 0 62.13 0 30.98 70 9.32 4.98" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="30.98 70 30.98 13.45 18.12 0 0 0 30.98 70" style="fill:${PREFIX}${
      colorArr[3]
    }"/><polygon points="18.12 0 44.02 0 30.98 29.18 18.12 0" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    W: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.14 70"><title>W_CH</title><polygon points="106.14 0 73.88 33.16 31.25 31.62 31.25 70 52.27 62.91 75.08 70 106.14 70 106.14 0" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="31.06 70 48.98 12.75 59.49 14.6 75.08 70 31.06 70" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="106.14 0 88.03 0 75.83 4.88 62.13 0 50.84 3.98 49.56 12.49 75.08 70 106.14 0" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="88.03 0 62.13 0 75.08 29.18 88.03 0" style="fill:${PREFIX}${
      colorArr[3]
    }"/><polygon points="15 9.01 0 0 0 70 31.06 70 15 9.01" style="fill:${PREFIX}${
      colorArr[4]
    }"/><polygon points="62.13 0 44.02 0 30.45 7.99 18.12 0 0 0 31.06 70 62.13 0" style="fill:${PREFIX}${
      colorArr[5]
    }"/><polygon points="44.02 0 18.12 0 31.06 29.18 44.02 0" style="fill:${PREFIX}${"00b2b8"}"/></svg>`,
    X: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.11 70.02"><title>X_CH</title><polygon points="0 0 27.25 23.57 24.18 53.12 0 70.02 0 0" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="36.44 21.01 62.12 0 62.12 70.02 41.21 55.69 36.44 21.01" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="0 0 18.11 0 31.06 12.1 44.01 0 62.12 0 31.06 50.41 0 0" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="18.11 0 44.01 0 31.06 21.02 18.11 0" style="fill:${PREFIX}${
      colorArr[3]
    }"/><polygon points="0 70.02 18.11 70.02 27.25 65.91 44.01 70.02 62.12 70.02 39.8 36.23 22.32 36.23 0 70.02" style="fill:${PREFIX}${
      colorArr[4]
    }"/><polygon points="18.11 70.02 44.01 70.02 31.06 50.41 18.11 70.02" style="fill:${PREFIX}${
      colorArr[5]
    }"/></svg>`,
    Y: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70"><title>Y_CH</title><polygon points="0 0 24.41 21.72 21.35 70 0 70 0 0" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="40.79 5.24 62.13 0 62.13 70 40.78 70 40.79 5.24" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="0.01 0 18.12 0 28.22 4.97 44.02 0 62.13 0 31.06 50.4 0.01 0" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="18.12 0 44.02 0 31.06 21.01 18.12 0" style="fill:${PREFIX}${
      colorArr[3]
    }"/><rect x="21.35" y="34.65" width="19.42" height="35.35" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`,
    Z: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.13 70.01"><title>Z_CH</title><polygon points="15.83 9.34 62.12 17.18 62.12 50.58 12.07 59.73 15.83 9.34" style="fill:${PREFIX}${
      colorArr[0]
    }"/><polygon points="0 70.01 7.95 50.58 62.12 50.58 62.12 70.01 0 70.01" style="fill:${PREFIX}${
      colorArr[1]
    }"/><polygon points="0 45.5 39.34 5.07 62.12 17.18 0 70 0 45.5" style="fill:${PREFIX}${
      colorArr[2]
    }"/><polygon points="0 17.18 34.4 17.18 0 45.5 0 17.18" style="fill:${PREFIX}${
      colorArr[3]
    }"/><rect width="62.13" height="17.18" style="fill:${PREFIX}${
      colorArr[4]
    }"/></svg>`
  };

  return `'${DATAURL}${SVGSTORE[letter]}'`;
};

const COLORARR = [`303030`, `2a59bd`, `fd4112`, `ff9ccf`, `ecdcc8`, `00b2b8`];

const randomLetter = (letter = "A") => {
  const COLOR_ARR = [...COLORARR];

  const shuffleColors = () => {
    for (let i = 0; i < COLOR_ARR.length; i += 1) {
      const randIndx = Math.floor(Math.random() * (COLOR_ARR.length - 1));
      const temp = COLOR_ARR[i];
      COLOR_ARR[i] = COLOR_ARR[randIndx];
      COLOR_ARR[randIndx] = temp;
    }
    return COLOR_ARR;
  };

  const COLORS = shuffleColors();

  return getSvgLetter(COLORS, letter);
};

export default randomLetter;
