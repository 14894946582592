import randomLetter from "./helpers/randomLetter";

export default [
  {
    key: "1",
    svg: randomLetter("M"),
    title: "MESSENGER",
    tech: ["REACT", "REDUX", "FIREBASE", "JS", "CSS"],
    desc: "Modern web messenger, built with fliudity in mind.",
    links: {
      demo: null,
      github: "https://github.com/Swordys/MessengerWEB"
    }
  },
  {
    key: "2",
    svg: randomLetter("V"),
    title: "MOVIE_CHARTS",
    tech: ["REACT", "REDUX", "CHART.JS", "JS", "CSS"],
    desc: "Movie performance data, based on a release year.",
    links: {
      demo: "https://swordys.github.io/movieStats",
      github: "https://github.com/Swordys/MovieStats"
    }
  },
  {
    key: "4",
    svg: randomLetter("X"),
    title: "POKER_API",
    tech: ["NODE", "EXPRESS", "JS"],
    desc: "Poker web API for analyzing player odds/hands.",
    links: {
      demo: null,
      github: "https://github.com/Swordys/PokerDeckApi"
    }
  },
  {
    key: "5",
    svg: randomLetter("I"),
    title: "REACT_SOCKET",
    tech: ["REACT", "EXPRESS", "SOCKET.IO", "CSS", "JS"],
    desc: "Live chat room implimentation using web socket library.",
    links: {
      demo: null,
      github: "https://github.com/Swordys/ReactSocketV1"
    }
  },
  {
    key: "6",
    svg: randomLetter("P"),
    title: "PORTFOLIO_V1",
    tech: ["JQUERY", "JS", "CSS"],
    desc: "Previous version of a portfolio website.",
    links: {
      demo: "https://portfolioold.netlify.com",
      github: "https://github.com/Swordys/PortfolioV2"
    }
  },
  {
    key: "8",
    svg: randomLetter("N"),
    title: "NOTE_FIREBASE",
    tech: ["REACT", "REDUX", "FIREBASE", "JS", "CSS"],
    desc: "Note creation/storing app built with Firebase DB.",
    links: {
      demo: "http://note-mate.herokuapp.com/",
      github: "https://github.com/Swordys/NoteFirebase"
    }
  },
  {
    key: "9",
    svg: randomLetter("Q"),
    title: "WEATHER_LIVE",
    tech: ["REACT", "AJAX", "JS", "CSS"],
    desc: "Instant weather information based on location.",
    links: {
      demo: "http://weather-fast.herokuapp.com",
      github: "https://github.com/Swordys/LiveWeather"
    }
  },
  {
    key: "10",
    svg: randomLetter("G"),
    title: "RETRO_RADIO",
    tech: ["JQUERY", "GREENSOCK", "JS", "HTML", "CSS"],
    desc: "Interactive radio for people with retro music taste.",
    links: {
      demo: "https://swordys.github.io/RetroWebRadio",
      github: "https://github.com/Swordys/RetroWebRadio"
    }
  },
  {
    key: "11",
    svg: randomLetter("K"),
    title: "RESPONSIVE_MOBILE",
    tech: ["JQUERY", "GREENSOCK", "JS", "HTML", "CSS"],
    desc: "Customizable photorealistic phone bulit with pure CSS.",
    links: {
      demo: "https://swordys.github.io/RealisticMobile",
      github: "https://github.com/Swordys/RealisticMobile"
    }
  },
  {
    key: "12",
    svg: randomLetter("O"),
    title: "ISOMETRIC_SERVER",
    tech: ["JQUERY", "JS", "HTML", "CSS"],
    desc: "Animated isometric server, inspired by Dribble shot.",
    links: {
      demo: "https://swordys.github.io/AnimatedServer",
      github: "https://github.com/Swordys/AnimatedServer"
    }
  }
];
