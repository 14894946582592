import React from "react";
import projectData from "../../assets/projectData";
import "../../styles/app/pages/projects.css";
import ProjectItem from "./ProjectItem";

const Projects = () => (
  <div className="app_page projects">
    <div className="projects_content">
      {projectData.map((data, i) => (
        <ProjectItem timing={i * 30} key={data.key} {...data} />
      ))}
    </div>
  </div>
);

export default Projects;
