import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/app/navigation/nav.css";

const Navigation = class extends React.Component {
  state = {
    hidden: true
  };

  render() {
    return (
      <React.Fragment>
        <div
          onClick={() => this.setState(prev => ({ hidden: !prev.hidden }))}
          className="nav-menu"
          role="button"
          tabIndex={0}
        >
          <div
            className={`nav-menu-mobile${
              this.state.hidden ? "" : " menu-close"
            }`}
          />
        </div>
        <nav
          role="presentation"
          onClick={() => {
            this.setState({ hidden: true });
          }}
          className={`nav${this.state.hidden ? " nav-hidden" : ""}`}
        >
          <div
            role="presentation"
            onClick={e => e.stopPropagation()}
            className="nav_links"
          >
            <NavLink exact to="/" className="nav_item">
              <span className="item_hov" />
              ABOUT
            </NavLink>
            <NavLink exact to="/projects" className="nav_item">
              <span className="item_hov" />
              PROJECTS
            </NavLink>
          </div>
        </nav>
      </React.Fragment>
    );
  }
};

export default Navigation;
