import React from "react";
import { MdCode, MdLink } from "react-icons/md";

const COLORS = {
  REACT: "#6f9ad1",
  REDUX: "#7e6fd1",
  JS: "#e8c943",
  CSS: "#6f80d1",
  HTML: "#dd7958",
  FIREBASE: "#e8b343",
  EXPRESS: "#b1b1b1",
  JQUERY: "#5871dd",
  GREENSOCK: "#99d16f",
  "CHART.JS": "#ed6f73",
  "SOCKET.IO": "#707070"
};

const ProjectItem = ({ title, tech, desc, links, timing, svg }) => (
  <div style={{ animationDelay: `${timing}ms` }} className="projects_item">
    <div className="project_item_content">
      <div className="content_image">
        <div
          className="content_image_letter"
          style={{
            backgroundImage: `url(${svg})`
          }}
        />
      </div>
      <div className="content_info">
        <h3 className="content_title">{title}</h3>
        <div className="content_tech">
          {tech.map(t => (
            <div
              key={t}
              style={{ backgroundColor: `${COLORS[t]}` }}
              className="content_tech_item"
            >
              {t}
            </div>
          ))}
        </div>
        <div className="content_desc">{desc}</div>
        <div className="content_links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="contnet_link_item"
            style={{
              pointerEvents: `${links.demo ? "initial" : "none"}`,
              opacity: `${links.demo ? "1" : "0.8"}`
            }}
            href={`${links.demo}`}
          >
            <MdLink />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="contnet_link_item"
            href={`${links.github}`}
          >
            <MdCode />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default ProjectItem;
