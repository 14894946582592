import React from "react";
import * as icon from "../../assets/Icons";
import randomLetter from '../../assets/helpers/randomLetter';
import resume from '../../assets/David_Saginashvili_Resume.PDF';
import "../../styles/app/pages/about.css";


const SKILL_DATA = [
  {
    name: "JS",
    icon: icon.javascript
  },
  {
    name: "C#",
    icon: icon.visual
  },
  {
    name: "PYTHON",
    icon: icon.python
  },
  {
    name: "NODE",
    icon: icon.nodejs
  },
  {
    name: "REACT",
    icon: icon.react
  },
  {
    name: "REDUX",
    icon: icon.redux
  },
  {
    name: "MONGODB",
    icon: icon.mongodb
  },
  {
    name: "G-CLOUD",
    icon: icon.gcloud
  },
  {
    name: "CSS",
    icon: icon.css
  },
  {
    name: "SCSS",
    icon: icon.sass
  },
  {
    name: "HTML",
    icon: icon.html
  },
  {
    name: "GIT",
    icon: icon.git
  },
  {
    name: "FIREBASE",
    icon: icon.firebase
  },
  {
    name: "NETLIFY",
    icon: icon.netlify
  },
  {
    name: "ADOBE XD",
    icon: icon.adobexd
  },
];

const CONTACT_DATA = [
  {
    icon: icon.adobeacrobatreader,
    name: "RESUME",
    link: resume
  },
  {
    icon: icon.toptal,
    name: "TOPTAL",
    link: "https://www.toptal.com/resume/david-saginashvili"
  },
  {
    icon: icon.github,
    name: "GITHUB",
    link: "https://github.com/swordys"
  },
  {
    icon: icon.stackoverflow,
    name: "STACKOVERFLOW",
    link: "https://stackoverflow.com/users/5843204/swordys"
  },
  {
    icon: icon.repl,
    name: "REPL",
    link: "https://repl.it/@Swordys"
  },
  {
    icon: icon.codepen,
    name: "CODEPEN",
    link: "https://codepen.io/swordys/"
  }
];

const About = () => (
  <div className="app_page about">
    <div className="about_content_contain">
      <div className="about_content about_pic">
        <div
          className="about_pic_img"
          style={{
            backgroundImage: `url(${randomLetter("F")})`
          }}
        />
      </div>
      <div className="about_content about_info">
        <div className="about_bio info_section">
          <h2 className="about_bio_title">hello*</h2>
          <div className="about_bio_info">
            <p>
              I’m David, a full-stack developer from Tbilisi. I love coming up
              elegant solutions to tricky problems, whether it be UX centric or
              technical.
            </p>
            <p>
              My approach to
              product-building comprises creating consistency, combining core foundations with the newest technologies, and focusing
              on minute details, making the user experience significantly better.
            </p>
          </div>
        </div>
        <div className="about_skills info_section">
          <div className="line_wrapper">
            <div className="line" />
            <h4 className="about_skills_title">SKILLSET</h4>
          </div>
          <ul className="about_skills_list">
            {SKILL_DATA.map(e => (
              <li key={e.name}>
                <div
                  className="skillset_icon"
                  style={{
                    backgroundImage: `url(${e.icon})`
                  }}
                />
                {e.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="about_contact info_section">
          <div className="line_wrapper">
            <div className="line" />
            <h4 className="about_contact_title">CONTACT</h4>
          </div>
          <div className="about_contact_info">
            <p>
              If you&apos;re interested or want to have a conversation, shoot me
              an email at&nbsp;
              <a href="mailto:contact@davidsaginashvili.com" target="_top">
                contact@davidsaginashvili.com
              </a>
            </p>
          </div>
          <div className="line_wrapper">
            <div className="line" />
            <h4 className="about_link_title">LINKS</h4>
          </div>
          <ul className="about_contact_list">
            {CONTACT_DATA.map(el => (
              <li key={el.name} className="about_contact_item">
                <div
                  className="contact_icon"
                  style={{
                    backgroundImage: `url(${el.icon})`
                  }}
                />
                <a target="_blank" rel="noopener noreferrer" href={el.link}>
                  {el.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default About;
