import React from "react";
import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Navigation from "./Navigation";
import About from "../components/pages/About";
import Projects from "../components/pages/Projects";

import "./css/transition.css";

const App = () => (
  <Route
    render={({ location }) => (
      <div className="app">
        <Navigation />
        <div className="page_container">
          <TransitionGroup component={null}>
            <CSSTransition
              key={location.pathname}
              timeout={350}
              classNames="fade"
            >
              <Switch location={location}>
                <Route exact path="/" component={About} />
                <Route exact path="/projects" component={Projects} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    )}
  />
);

export default App;
